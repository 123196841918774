import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import headerImage from '@assets/services/data-viz/data-viz-bg.jpg';

const MethodWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepItem = styled.div`
  display: flex;
  height: 70px;
  font-weight: 600;
  font-size: 16px;
  span {
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.colors.primary.base};
    border-radius: 50%;
    position: relative;
    top: 5px;
    margin-right: 15px;
    display: flex;
    align-items: end;
    justify-content: center;

    &:not(.first):after {
      content: '';
      width: 2px;
      height: 70px;
      background-color: ${props => props.theme.colors.primary.base};
      display: flex;
      align-items: center;
    }
  }
`;

const StepWrapper = styled.div`
  width: fit-content;
  margin: 20px auto;
`;

const DataVisualization = () => {
  return (
    <Layout>
      <Helmet title={'Data Visualization'} />
      <PageHero image={headerImage} />
      <Heading title="Data Visualization" noHeadingIcon={true}>
        <p>
          Did you ever realise why it used to seem like 'statistics' was the
          easiest chapter in your math curriculum when you were in high school?
          Let us agree that, this was one topic where everyone used to score
          well. Why is that? You might think that it's because it was the
          easiest out of all chapters, but that's not the truth, the truth is,
          it was easy to understand, it was just not all numbers and values, it
          was possible to understand those number with the help of graph,
          charts, bars, etc. and that's what made it fun and easy to understand.
          And that is how we can help you in understanding and analyzing your
          analytics.
        </p>
        <p>
          With our unique data visualization algorithms and techniques, we
          enable decision-makers to see analytics presented visually, so they
          can grasp difficult concepts or identify new patterns. Data
          visualization is a quick, easy way to convey concepts universally –
          and you can experiment with different scenarios by making slight
          adjustments.
        </p>
      </Heading>
      <MethodWrapper>
        <Container>
          <SectionHeading>
            How our methods can help you boost up your business
          </SectionHeading>
          <StepWrapper>
            <StepList>
              <StepItem>
                <span className="first" />
                Comprehend information quickly
              </StepItem>
              <StepItem>
                <span />
                Identify relationships and patterns
              </StepItem>
              <StepItem>
                <span />
                Pinpoint emerging trends
              </StepItem>
              <StepItem>
                <span />
                Communicate the story to others
              </StepItem>
            </StepList>
          </StepWrapper>
        </Container>
      </MethodWrapper>
    </Layout>
  );
};

export default DataVisualization;
